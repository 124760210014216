<template>
  <Toast />
  <div class="checkout-page w-full pb-10 relative">
    <div
      v-if="isPayNow"
      class="payNow-loader flex  absolute z-10 w-full h-full justify-center items-center "
      style="transition: opacity .2s ease;"
    >
      <Spinner :loading="isPayNow" />
    </div>
    <div class="order-details">
      <Block icon="location" title="Shipping Address">
        <template #action>
          <!-- <button class="card-btn" @click="$router.push('/account/stores')">
            <Icon name="plus"/>
            <span>Add New</span>
          </button> -->
        </template>
        <Spinner :loading="addresses.loading" />
        {{ addresses.loading == false ? checkIfAddressNotFound() : null }}
        <RadioGroup>
          <RadioGroupLabel class="sr-only">Shipping Addresss</RadioGroupLabel>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <RadioGroupOption
              as="template"
              v-for="address in addresses.data.filter(
                (r) => r.trade_gecko_id == shippingId
              )"
              :key="address.id"
              :value="address"
            >
              <div
                class="checkblock"
                @click="selectAddress(address, 'Shipping')"
              >
                <div class="checkmark-label">
                  <div
                    :class="[
                      shippingId == address.trade_gecko_id
                        ? 'active'
                        : 'inactive',
                      'checkmark',
                    ]"
                  >
                    <svg
                      v-if="shippingId == address.trade_gecko_id"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex flex-col h-full justify-between">
                  <div class="block pt-2">
                    <RadioGroupLabel as="p" class="item-name">{{
                      address.nickname ? address.nickname : address.company_name
                    }}</RadioGroupLabel>
                    <RadioGroupDescription as="div" class="item-desc">
                      <p>{{ address.address1 }}</p>
                      <p>{{ address.address2 }}</p>
                      <p>
                        {{ address.city }}, {{ address.state }}
                        {{ address.zip_code }}
                      </p>
                    </RadioGroupDescription>
                  </div>
                  <div
                    class="flex items-center justify-between text-xs font-medium"
                  >
                    <RadioGroupDescription
                      as="span"
                      class="text-theme-body font-medium uppercase tracking-wide"
                      >{{
                        address.licenses[0]
                          ? "LICENSE #" + address.licenses[0].license_number
                          : null
                      }}</RadioGroupDescription
                    >
                    <span
                      class="bg-theme-green-light text-theme-green-dark inline-flex items-center px-2.5 py-0.5 rounded-full text-tiny font-semibold uppercase"
                      >Valid</span
                    >
                  </div>
                </div>
                <div
                  :class="[
                    shippingId == address.trade_gecko_id
                      ? 'border-theme-indigo border-opacity-50'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>

            <div
              v-for="address in addresses.data.filter(
                (r) => r.trade_gecko_id == shippingId
              )"
              :key="address.id"
              :value="address"
            >
              <router-link to="/home">
                <div class="checkblock back-block">
                  <span class="go-back">
                    <Icon name="left-arrow"></Icon>
                  </span>
                  <div class="flex flex-col h-full justify-between">
                    <div class="block">
                      <RadioGroupLabel as="p" class="item-name"
                        >Go back</RadioGroupLabel
                      >
                      <RadioGroupDescription as="div" class="item-desc"
                        >to change your address</RadioGroupDescription
                      >
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </RadioGroup>

        <div class="block mt-6">
          <label for="billing-address" class="address-checkbox">
            <input
              id="billing-address"
              aria-describedby="billing-address"
              v-model="isSameBilling"
              name="billing-address"
              type="checkbox"
            />
            <span>Same as Billing Address</span>
          </label>
        </div>
      </Block>

      <Block icon="location" title="Billing Address" v-if="!isSameBilling">
        <template #action>
          <button class="card-btn" @click="show">
            <Icon name="plus" />
            <span>Add New</span>
          </button>
        </template>
        <RadioGroup>
          <RadioGroupLabel class="sr-only">Billing Address</RadioGroupLabel>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <RadioGroupOption
              as="template"
              v-for="address in addresses.data"
              :key="address.id"
              :value="address"
            >
              <div
                class="checkblock"
                @click="selectAddress(address, 'Billing')"
              >
                <div class="flex w-full justify-end -mb-5">
                  <div
                    :class="[
                      billingId == address.trade_gecko_id
                        ? ' bg-theme-indigo border-theme-indigo text-white'
                        : 'bg-white border-theme-border ',
                      'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                    ]"
                  >
                    <svg
                      v-if="billingId == address.trade_gecko_id"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="block">
                  <RadioGroupLabel as="p" class="item-name">{{
                    address.city
                  }}</RadioGroupLabel>
                  <RadioGroupDescription as="div" class="item-desc">{{
                    address.label
                  }}</RadioGroupDescription>
                  <!-- <div class="flex items-center justify-between text-xs font-medium">
                    <RadioGroupDescription as="span" class="text-theme-body font-medium uppercase tracking-wide">{{ address.licenses[0] ? 'LICENSE #'+address.licenses[0].license_number : null }}</RadioGroupDescription>
                    <span class="bg-theme-green-light text-theme-green-dark inline-flex items-center px-2.5 py-0.5 rounded-full text-tiny font-semibold uppercase">Valid</span>
                  </div> -->
                </div>
                <div
                  :class="[
                    billingId == address.trade_gecko_id
                      ? 'border-theme-indigo border-opacity-50'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </Block>
      <Block icon="aeroplane" title="Shipping Method">
        <RadioGroup v-model="shippingAddress">
          <RadioGroupLabel class="sr-only">Shipping Method</RadioGroupLabel>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <Spinner :loading="shippingData.loading" />
            <RadioGroupOption
              v-for="shipping in shippingData.data.options"
              :key="shipping.code"
              :value="shipping"
              v-slot="{ checked }"
              @click="selectShippingMethod(shipping)"
            >
              <ShippingOption :checked="checked" :shipping="shipping" />
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </Block>

      <Block
        icon="medusa-icon-bg-filled"
        title="Medusa Coins"
        v-if="checkoutData.data.canApplyPoints == true"
      >
        <template #title>
          <div class="flex items-center gap-2">
            <span class="text-base !leading-none font-medium text-theme-body"
              >Balance : {{ commaFormat(coins.data.total_points) }} Coins</span
            >
            <span>
              <tippy interactive>
                <span class="cursor-pointer"
                  ><Icon
                    name="info"
                    class="w-5 h-5 -mt-1 text-theme-secondary font-medium opacity-60"
                /></span>
                <template #content>
                  <p>
                    A minimum purchase of $500 is required to redeem Medusa
                    Coins
                  </p>
                </template>
              </tippy>
            </span>
          </div>
        </template>
        <RadioGroup v-model="discountCoins">
          <RadioGroupLabel class="sr-only">Medusa Coins</RadioGroupLabel>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <RadioGroupOption
              as="template"
              v-for="coin in coins.data.points"
              :key="coin.value"
              :value="coin"
              v-slot="{ checked }"
              @click="appliedPoints(coin)"
            >
              <div
                :class="[
                  !coin.disabled
                    ? 'border-theme-border hover:border-gray-400'
                    : 'border-transparent ',
                  { 'opacity-50 pointer-events-none': coin.disabled },
                ]"
                class="relative flex flex-col rounded-lg border bg-white shadow-sm p-3 cursor-pointer sm:flex sm:justify-between focus:outline-none select-none"
              >
                <div class="flex w-full justify-end -mb-3">
                  <div
                    :class="[
                      checked
                        ? ' bg-theme-indigo border-theme-indigo text-white'
                        : 'bg-white border-theme-border ',
                      'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                    ]"
                  >
                    <svg
                      v-if="checked"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex items-start space-x-4 mb-3">
                  <Icon
                    :name="
                      !coin.disabled
                        ? 'medusa-icon-bg-filled'
                        : 'medusa-icon-grey'
                    "
                    class="w-8 h-8 flex-shrink-0 mt-1"
                  />
                  <div class="block flex-grow">
                    <RadioGroupLabel
                      as="p"
                      class="text-base font-semibold text-theme-secondary text-opacity-80 leading-none mb-2"
                      >${{ commaFormat(coin.discount) }} OFF</RadioGroupLabel
                    >
                    <RadioGroupDescription
                      as="div"
                      class="block text-base text-theme-body-light font-medium leading-none"
                      >{{
                        commaFormat(coin.points)
                      }}
                      Coins</RadioGroupDescription
                    >
                  </div>
                </div>
                <div
                  :class="[
                    checked
                      ? 'border-theme-indigo border-opacity-50'
                      : coin.disabled
                      ? 'border-dashed'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
                <span
                  class="bg-gray-400 rounded-b absolute inset-x-0 bottom-0 text-center text-xs font-semibold py-1 leading-none"
                  v-if="coin.disabled && coins.data.total_points < coin.points"
                >
                  {{
                    commaFormat(
                      parseInt(coin.points) - parseInt(coins.data.total_points)
                    )
                  }}
                  Coins to go</span
                >
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </Block>
      <div
        v-if="shippingAddress"
        class=" gap-8"
        :class="
          checkoutData.data.storeCredit > 0
            ? 'grid grid-cols-1 xl:grid-cols-2'
            : 'grid grid-cols-1'
        "
      >
        <Block title="Discount Coupon" icon="coupon">
          <div
            class="inline-flex items-center relative px-2 py-1  rounded-md leading-5 bg-theme-bg border border-gray-100 w-full max-w-sm"
            :class="
              checkoutData.data.coupons.filter((r) => r.type != 'C').length > 0
                ? 'pointer-events-none'
                : ''
            "
          >
            <input
              id="coupon"
              name="coupon"
              class="p-2 border-0 text-md font-light bg-transparent placeholder-theme-body-light focus:outline-non focus:ring-0 flex-grow "
              placeholder="Enter Discount Code"
              type="search"
              v-model="coupon"
              autocomplete="off"
            />
            <button
              class="bg-transparent text-theme-secondary text-sm focus:outline-none focus:ring:0 focus:border-none uppercase font-normal px-3 py-2 rounded "
              @click="applyCoupon"
              v-if="!couponClicked"
            >
              {{
                checkoutData.data.coupons.filter((r) => r.type != "C").length >
                0
                  ? "Applied"
                  : "Apply"
              }}
            </button>
            <Spinner v-else className="w-5 h-5 mr-2" :loading="couponClicked" />
          </div>
        </Block>
        <Block
          title="Store Credit"
          icon="star-filled"
          v-if="checkoutData.data.storeCredit > 0"
        >
          <template #action>
            <span class="text-md text-theme-secondary font-normal"
              >Available Credit: ${{
                checkoutData.data.canApplyStoreCredit
                  ? checkoutData.data.storeCredit
                  : parseFloat(
                      checkoutData.data.storeCredit - store_credit
                    ).toFixed(2)
              }}</span
            >
          </template>

          <div
            class="inline-flex items-center relative px-2 py-1  rounded-md leading-5 bg-theme-bg border border-gray-100 w-full max-w-sm"
          >
            <div
              class="number-input w-full transparent-bg transparent-border"
              v-bind="$attrs"
            >
              <span>$</span>
              <input
                v-model="store_credit"
                type="number"
                ref="input"
                spellcheck="false"
                @keyup="checkValueStoreCredit"
                autocomplete="off"
                :disabled="!checkoutData.data.canApplyStoreCredit"
              />
            </div>
            <button
              :class="
                checkoutData.data.canApplyStoreCredit
                  ? 'hover:text-black'
                  : 'pointer-events-none'
              "
              class="bg-transparent text-theme-secondary text-sm focus:outline-none focus:ring:0 focus:border-none uppercase font-normal px-3 py-2 rounded"
              @click="applyStoreCredit(store_credit)"
            >
              {{ checkoutData.data.canApplyStoreCredit ? "Apply" : "Applied" }}
            </button>
          </div>
        </Block>
      </div>
      <Block
        v-if="
          selectedShippingDelivery && !selectedPaylater && getGrandTotal() > 0
        "
        icon="credit-card"
        title="Pay Now"
      >
        <template #action v-if="checkoutData.data?.IsUSA != 0">
          <div class="inline-flex items-center gap-2">
            <div class="inline-flex items-center gap-2">
              <span class="pr-1 sm:pr-2">
                <tippy interactive>
                  <span class="cursor-pointer"
                    ><Icon
                      name="info"
                      class="w-5 h-5 text-theme-secondary font-medium opacity-60"
                  /></span>
                  <template #content>
                    <p>
                      Payment for orders can be split across 2 credit or debit
                      cards. This requires at least 2 cards saved to your
                      profile
                    </p>
                  </template>
                </tippy>
              </span>
              <button
                v-if="cards.data.length >= 1 && !splitCards.opt1.card"
                class="card-btn"
                :class="cards.data.length > 1 ? '' : 'opacity-60'"
                @click="cards.data.length > 1 ? openSplitPayModal() : ''"
              >
                <Icon name="split" />
                <span>Split Payment</span>
              </button>
            </div>
            <button
              v-if="!splitCards.opt1.card && checkoutData.data?.collectTax == 0"
              class="card-btn"
              @click="openPaymentModal"
            >
              <Icon name="plus" />
              <span class="hidden sm:inline">Add New Card</span>
              <span class="sm:hidden">New</span>
              <Icon class="sm:hidden ml-0.5" name="credit-card" />
            </button>
          </div>
        </template>
        <RadioGroup v-if="!splitCards.opt1.card" v-model="selectedPayment">
          <RadioGroupLabel class="sr-only">Payment Method</RadioGroupLabel>
          <Spinner :loading="cards.loading" />
          <p class="text-center text-red-600 mb-3">{{ cardErrorMsg }}</p>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
            v-if="!cards.loading"
          >
            <RadioGroupOption
              as="template"
              v-for="card in cards.data"
              :key="card.name"
              :value="card"
              v-slot="{ checked }"
              :disabled="card.expired == 1"
              @click="
                selectCreditCard(card.id, card.cc_number, card.expired == 1)
              "
            >
              <div
                class="checkblock"
                :class="card.expired === 1 ? 'border-dashed' : ''"
                :style="
                  card.expired === 1 ? 'border-width: 2px !important' : ''
                "
              >
                <div class="flex w-full justify-end -mb-5">
                  <span
                    @click="removeCard(card)"
                    class="inline-block cursor-pointer z-50"
                    v-if="card.expired === 1"
                  >
                    <Icon name="delete" class="w-5 h-5" />
                  </span>
                  <div
                    v-else
                    :class="[
                      checked
                        ? ' bg-theme-indigo border-theme-indigo text-white'
                        : 'bg-white border-theme-border ',
                      'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                    ]"
                  >
                    <svg
                      v-if="checked"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex h-full flex-col justify-between">
                  <div :class="{ 'pt-2': !card.nickname }">
                    <span class="item-nickname -mt-1" v-if="card.nickname">
                      {{ card.nickname }}</span
                    >
                    <RadioGroupLabel
                      as="p"
                      class="item-name"
                      :class="
                        card.expired === 1
                          ? 'text-theme-secondary text-opacity-80'
                          : ''
                      "
                      >{{ card.cc_name }}</RadioGroupLabel
                    >
                    <RadioGroupDescription
                      as="div"
                      class="item-desc"
                      :class="
                        card.expired === 1
                          ? 'text-theme-secondary text-opacity-80'
                          : ''
                      "
                      >{{
                        "xxxx-xxxx-xxxx-" + card.cc_number.slice(-4)
                      }}</RadioGroupDescription
                    >
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <RadioGroupDescription
                        as="span"
                        class=" text-theme-dark text-sm font-normal"
                        :class="
                          card.expired === 1 ? 'text-theme-body-light' : ''
                        "
                        >{{ card.cc_exp.slice(0, 2) }}/{{
                          card.cc_exp.slice(-2)
                        }}</RadioGroupDescription
                      >
                      <span
                        v-if="card.expired == 1"
                        class="inline-flex items-center px-4 py-0.5 rounded-full text-tiny font-semibold uppercase bg-theme-red bg-opacity-20 text-theme-red"
                        >Expired</span
                      >
                    </div>

                    <div class="w-8 h-7 flex items-center justify-end">
                      <img
                        :src="
                          card.cc_number[0] == 4
                            ? '/images/card-providers/visa.png'
                            : card.cc_number[0] == 5
                            ? '/images/card-providers/master-card.png'
                            : card.cc_number[0] == 6
                            ? '/images/card-providers/Discover-logo.png'
                            : card.cc_number[0] == 3
                            ? '/images/card-providers/american-express.png'
                            : ''
                        "
                        class="max-w-full"
                      />
                    </div>
                  </div>
                </div>
                <div
                  :class="[
                    checked
                      ? 'border-theme-indigo border-opacity-50'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
            <RadioGroupOption
              as="template"
              v-for="bz in getData()"
              :key="bz.name"
              :value="bz"
              v-slot="{ checked }"
              @click="selectPayLaterOption(bz)"
            >
              <div class="checkblock">
                <div class="flex w-full justify-end -mb-2">
                  <div
                    :class="[
                      checked
                        ? ' bg-theme-indigo border-theme-indigo text-white'
                        : 'bg-white border-theme-border ',
                      'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                    ]"
                  >
                    <svg
                      v-if="checked"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="block">
                  <RadioGroupLabel as="p" class="item-name">{{
                    bz.name
                  }}</RadioGroupLabel>
                  <RadioGroupDescription as="div" class="item-desc">{{
                    bz.desc
                  }}</RadioGroupDescription>
                  <div class="flex items-center justify-between">
                    <RadioGroupDescription
                      as="span"
                      class=" text-theme-dark text-sm font-normal"
                    ></RadioGroupDescription>
                    <div class="w-8 h-7 flex items-center justify-end">
                      <img :src="bz.provider" class="max-w-full" />
                    </div>
                  </div>
                </div>
                <div
                  :class="[
                    checked
                      ? 'border-theme-indigo border-opacity-50'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <!-- <div class="block mt-6">
          <button
            v-if="
              cards.data.length > 1 && checkoutData.data?.IsUSA != 0 && !splitCards.opt1.card
            "
            class="flex items-center space-x-2 bg-transparent text-base font-medium uppercase tracking-wide text-theme-purple-dark"
            @click="openSplitPayModal"
          >
            <Icon name="split" class="w-5 h-5" />
            <span class="underline">
              split payments
            </span>
          </button>
        </div> -->
        <!-- split -->
        <RadioGroup v-if="splitCards.opt1.card">
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-6"
          >
            <div>
              <RadioGroupOption as="template" v-slot="{ checked }">
                <div class="checkblock min-height">
                  <div class="block">
                    <span
                      class="text-xs font-normal text-theme-body mb-1"
                      v-if="splitCards.opt1.card.nickname"
                    >
                      {{ splitCards.opt1.card.nickname }}</span
                    >
                    <RadioGroupLabel as="p" class="item-name">{{
                      splitCards.opt1.card.cc_name
                    }}</RadioGroupLabel>
                    <RadioGroupDescription as="div" class="item-desc">{{
                      "xxxx-xxxx-xxxx-" +
                        splitCards.opt1.card.cc_number.slice(-4)
                    }}</RadioGroupDescription>
                    <div class="flex items-center justify-between">
                      <RadioGroupDescription
                        as="span"
                        class=" text-theme-dark text-sm font-normal"
                        >{{ splitCards.opt1.card.cc_exp.slice(0, 2) }}/{{
                          splitCards.opt1.card.cc_exp.slice(-2)
                        }}</RadioGroupDescription
                      >
                      <div class="w-8 h-7 flex items-center justify-end">
                        <img
                          :src="
                            splitCards.opt1.card.cc_number[0] == 4
                              ? '/images/card-providers/visa.png'
                              : splitCards.opt1.card.cc_number[0] == 5
                              ? '/images/card-providers/master-card.png'
                              : splitCards.opt1.card.cc_number[0] == 6
                              ? '/images/card-providers/Discover-logo.png'
                              : splitCards.opt1.card.cc_number[0] == 3
                              ? '/images/card-providers/american-express.png'
                              : ''
                          "
                          class="max-w-full"
                        />
                      </div>
                    </div>
                    <p
                      class="text-sm flex items-start leading-none gap-1 bg-theme-purple-dark bg-opacity-30 px-2.5 py-3 absolute inset-x-0 bottom-0 text-theme-secondary font-medium"
                    >
                      ${{ currencyFormat(splitCards.opt1.amount) }} to be billed
                      to this card
                    </p>
                  </div>
                  <div
                    :class="[
                      checked ? 'border-opacity-50' : 'border-transparent',
                      'absolute -inset-px rounded-lg border-2 pointer-events-none',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
            <div v-if="splitCards.opt2.card">
              <RadioGroupOption as="template" v-slot="{ checked }">
                <div class="checkblock min-height">
                  <div class="block">
                    <span
                      class="text-xs font-normal text-theme-body mb-1"
                      v-if="splitCards.opt2.card.nickname"
                    >
                      {{ splitCards.opt2.card.nickname }}</span
                    >
                    <RadioGroupLabel as="p" class="item-name">{{
                      splitCards.opt2.card.cc_name
                    }}</RadioGroupLabel>
                    <RadioGroupDescription as="div" class="item-desc">{{
                      "xxxx-xxxx-xxxx-" +
                        splitCards.opt2.card.cc_number.slice(-4)
                    }}</RadioGroupDescription>
                    <div class="flex items-center justify-between">
                      <RadioGroupDescription
                        as="span"
                        class=" text-theme-dark text-sm font-normal"
                        >{{ splitCards.opt2.card.cc_exp.slice(0, 2) }}/{{
                          splitCards.opt2.card.cc_exp.slice(-2)
                        }}</RadioGroupDescription
                      >
                      <div class="w-8 h-7 flex items-center justify-end">
                        <img
                          :src="
                            splitCards.opt2.card.cc_number[0] == 4
                              ? '/images/card-providers/visa.png'
                              : splitCards.opt2.card.cc_number[0] == 5
                              ? '/images/card-providers/master-card.png'
                              : splitCards.opt2.card.cc_number[0] == 6
                              ? '/images/card-providers/Discover-logo.png'
                              : splitCards.opt2.card.cc_number[0] == 3
                              ? '/images/card-providers/american-express.png'
                              : ''
                          "
                          class="max-w-full"
                        />
                      </div>
                    </div>
                    <p
                      class="text-sm flex items-start leading-none gap-1 bg-theme-purple-dark bg-opacity-30 px-2.5 py-3 absolute inset-x-0 bottom-0 text-theme-secondary font-medium"
                    >
                      ${{ currencyFormat(splitCards.opt2.amount) }} to be billed
                      to this card
                    </p>
                  </div>
                  <div
                    :class="[
                      checked ? 'border-opacity-50' : 'border-transparent',
                      'absolute -inset-px rounded-lg border-2 pointer-events-none',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </div>
        </RadioGroup>
        <div v-if="splitCards.opt1.card" class="flex justify-end">
          <button
            class="flex items-center space-x-2 bg-transparent text-base font-medium uppercase tracking-wide text-theme-purple-dark"
            @click="resetSplitCards"
          >
            <Icon name="reload" class="w-5 h-5" />
            <span class="underline"> Clear</span>
          </button>
        </div>
        <!-- split -->
      </Block>
      <Block
        v-if="
          selectedShippingDelivery &&
            !selectedPayment &&
            !splitCards.opt1.card &&
            checkoutData.data?.IsUSA != 0 &&
            getGrandTotal() > 0
        "
        icon="timer"
        title="Pay Later"
      >
        <RadioGroup v-model="selectedPaylater">
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <RadioGroupOption
              as="template"
              v-for="item in checkoutData.data.isPaymentTerm
                ? shippingAddress.title == 'Pick Up At Medusa'
                  ? paylaterOptions
                  : paylaterOptions.filter((r) => r.id != 2)
                : shippingAddress.title == 'Pick Up At Medusa'
                ? paylaterOptions.filter((r) => r.id != 1)
                : []"
              :key="item.name"
              :value="item"
              v-slot="{ checked }"
              @click="selectPayLaterOption(item)"
            >
              <div class="checkblock">
                <div class="flex w-full justify-end -mb-2">
                  <div
                    :class="[
                      checked
                        ? ' bg-theme-indigo border-theme-indigo text-white'
                        : 'bg-white border-theme-border ',
                      'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                    ]"
                  >
                    <svg
                      v-if="checked"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="block">
                  <RadioGroupLabel as="p" class="item-name">{{
                    item.name
                  }}</RadioGroupLabel>
                  <RadioGroupDescription as="div" class="item-desc">{{
                    item.desc ? item.desc : checkoutData.data.paymentTerms
                  }}</RadioGroupDescription>
                  <div class="flex items-center justify-end">
                    <div class="w-auto h-7 flex items-center justify-end">
                      <img :src="item.provider" />
                    </div>
                  </div>
                </div>
                <div
                  :class="[
                    checked
                      ? 'border-theme-indigo border-opacity-50'
                      : 'border-transparent',
                    'absolute -inset-px rounded-lg border-2 pointer-events-none',
                  ]"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </Block>

      <Block icon="credit-card" title="Order Details">
        <!-- <div class="flex items-stretch -m-6"> -->
        <div class="flex flex-col xl:flex-row -m-6">
          <div class="w-full xl:w-1/2">
            <!-- Order Details -->
            <OrderSummary
              :summary="checkoutData"
              :store="checkoutData.data.appliedStoreCredit ?? null"
              @removePoint="removePoint"
              @removeCoupon="removeCoupon"
              @removeStoreCredit="removeStoreCredit"
            />
          </div>
          <!-- Billing/Shipping Details -->
          <div class="billing-info">
            <dl>
              <div>
                <dt class="address-title">Ship to</dt>
                <dd class="mt-2.5">
                  <span
                    class="address-info highlight"
                    v-if="shipTo?.nickname || shipTo?.company_name"
                    >{{
                      shipTo.nickname ? shipTo.nickname : shipTo.company_name
                    }}</span
                  >
                  <span class="address-info">{{
                    shipTo ? shipTo.address1 : null
                  }}</span>
                  <span class="address-info">{{
                    shipTo ? shipTo.address2 : null
                  }}</span>
                  <span class="address-info">
                    {{ shipTo ? shipTo.city : null }},
                    {{ shipTo ? shipTo.state : null }}
                    {{ shipTo ? shipTo.zip_code : null }}
                  </span>
                </dd>
                <div
                  class="flex flex-col items-start space-y-2 mt-8"
                  v-if="selectedCardNum"
                >
                  <span class="flex-shrink-0 text-sm uppercase">Pay Via</span>
                  <div class="flex space-y-3 flex-col">
                    <div class="flex items-center space-x-3">
                      <div class="block flex-shrink-0 w-8">
                        <img
                          :src="
                            cardlogo.substring(0, 1) == 4
                              ? '/images/card-providers/visa.png'
                              : cardlogo.substring(0, 1) == 5
                              ? '/images/card-providers/master-card.png'
                              : cardlogo.substring(0, 1) == 6
                              ? '/images/card-providers/Discover-logo.png'
                              : cardlogo.substring(0, 1) == 3
                              ? '/images/card-providers/american-express.png'
                              : null
                          "
                          alt="Visa"
                          class="max-w-full"
                        />
                      </div>
                      <p class="text-sm text-theme-body">
                        {{ selectedCardNum }}
                      </p>
                    </div>
                    <!-- <div class="flex items-center space-x-3">
                      <div class="block flex-shrink-0 w-8">
                        <img src="/images/card-providers/master-card.png" alt="Visa" class="max-w-full">
                      </div>
                      <p class="text-sm text-theme-body">XXXX-XXXX-XXXX-4059</p>
                    </div> -->
                  </div>
                </div>
              </div>
              <div>
                <dt class="summary-label address-title">Bill to</dt>
                <dd class="mt-2.5">
                  <span class="address-info">{{
                    billTo ? billTo.address1 : null
                  }}</span>
                  <span class="address-info">{{
                    billTo ? billTo.address2 : null
                  }}</span>
                  <span class="address-info">
                    {{ billTo ? billTo.city : null }},
                    {{ billTo ? billTo.state : null }}
                    {{ billTo ? billTo.zip_code : null }}</span
                  >
                </dd>
                <!-- <div class="flex flex-col items-start space-y-2 mt-8" v-if="selectedShippingDelivery">
                  <span class="flex-shrink-0 text-sm uppercase">Ships By</span>
                  <div class="flex space-y-3 flex-col">
                    <div class="flex items-center space-x-3">
                      <div class="block flex-shrink-0 w-8">
                        <img src="/images/fedex.png" alt="Visa" class="max-w-full">
                      </div>
                      <p class="text-sm text-theme-body">Est Delivery {{ selectedShippingDelivery }}</p>
                    </div>
                  </div>
                </div> -->
              </div>
            </dl>
          </div>
        </div>
        <div
          class="flex md:flex-row flex-col gap-8 md:items-center justify-between pt-6 mt-4"
          :class="
            (selectedCreditCard && checkoutData.btnEnabled) ||
            getGrandTotal() == 0
              ? ''
              : 'disp-none'
          "
        >
          <div
            class="flex items-center cursor-pointer"
            v-if="!enableRef && !checkoutData.data.refNumber"
            @click="enableRef = true"
          >
            <span class="text-sm text-brand-secondary"
              >Add your own PO number</span
            >
            <tippy class="ml-1.5 h-4" interactive>
              <Icon
                name="info"
                class="w-4 h-4 text-theme-secondary opacity-70"
              />
              <template #content>
                <p class="text-left px-1.5 text-xs">
                  You can include an optional purchase order number for your own
                  reference. This will appear on the invoice and packing slip
                  for this order.
                </p>
              </template>
            </tippy>
          </div>

          <div
            v-else
            class="inline-flex items-center relative px-2 py-1  rounded-md leading-5 bg-theme-bg border border-gray-100 w-full max-w-xs"
          >
            <input
              id="refNumber"
              name="refNumber"
              class="px-2 py-1.5 border-0 text-sm font-light bg-transparent placeholder-theme-body-light focus:outline-non focus:ring-0 flex-grow "
              placeholder="Enter PO Number"
              type="search"
              v-model="checkoutData.data.refNumber"
              autocomplete="off"
              maxlength="24"
              @input="saveRefNumber"
            />
            <tippy class="ml-1.5" interactive>
              <Icon
                name="info"
                class="w-5 h-5 text-theme-secondary opacity-70"
              />
              <template #content>
                <p class="text-left px-1.5 text-xs">
                  You can include an optional purchase order number for your own
                  reference. This will appear on the invoice and packing slip
                  for this order.
                </p>
              </template>
            </tippy>
            <button
              class="inline-flex items-center space-x-0.5 bg-transparent text-theme-secondary text-opacity-75 text-sm focus:outline-none focus:ring:0 focus:border-none uppercase font-normal px-2 py-1 rounded "
            >
              <Spinner className="w-5 h-5" :loading="ref_loading" />
              <div
                v-if="!ref_loading && checkoutData.data.refNumber"
                class="bg-theme-indigo border-theme-indigo text-white border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center"
              >
                <Icon name="checkmark" />
              </div>
            </button>
          </div>
          <div>
            <Spinner
              className="w-10 h-10"
              :loading="!checkoutData.btnEnabled"
            ></Spinner>
            <Button
              :title="'Pay $' + currencyFormat(getGrandTotal())"
              class="font-medium"
              @mouseup="payment"
            />
          </div>
        </div>
      </Block>
    </div>
    <vue3-snackbar
      top
      center
      success="#BD976E"
      :duration="10000"
    ></vue3-snackbar>
    <div class="order-summary">
      <div class="w-full bg-white">
        <OrderItems :items="sortItems(checkoutData.items)" />
        <Spinner :loading="checkoutData.loading" />
        <div class="info-title border-t border-theme-border border-opacity-50">
          <div class="title">
            <Icon name="package-filled" />
            <span>Order Total</span>
          </div>
          <span class="price"
            >${{ commaFormat(getTotal(checkoutData.items).toFixed(2)) }}</span
          >
        </div>
      </div>

      <TaxSummary
        v-if="checkoutData.data.taxTotal"
        class="w-full bg-white"
        :data="checkoutData.data.taxLineItems"
        :total="checkoutData.data.taxTotal"
      />
      <OrderSummary
        class="on-summary"
        :summary="checkoutData"
        :store="checkoutData.data.appliedStoreCredit ?? null"
        :coins="coinsEarned()"
        @removePoint="removePoint"
        @removeCoupon="removeCoupon"
        @removeStoreCredit="removeStoreCredit"
      />
    </div>

    <!-- Address modal -->
    <ModalFull
      :is-visible="showAddressModal"
      :reset-container="true"
      @close="showAddressModal = false"
    >
      <Block
        icon="shop"
        title="Add New Location"
        :streachActions="true"
        class="modal wd-50"
      >
        <template #action>
          <div
            class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4"></div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="showAddressModal = false"
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div
            class="flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-8 "
            v-show="currentStep === 1"
          >
            <div class="col-span-2 input">
              <Input
                label="Address Line 1"
                v-model:modelValue="address.address1"
                placeholder="Address Line 1"
              />
              <span
                v-if="isSubmit && v$.address.address1.$silentErrors.length > 0"
                class="flex text-left text-sm font-light text-red-400 py-2"
              >
                <span
                  v-if="
                    v$.address.address1.$silentErrors[0].$validator ==
                      'required'
                  "
                  >Address is required.</span
                >
              </span>
              <span
                v-if="isSubmit && address.error"
                class="flex text-left text-sm font-light text-red-400 py-2"
              >
                <span
                  v-if="
                    address.error.address1[0] ==
                      'The address1 has already been taken.'
                  "
                  >The Address has already been taken.</span
                >
              </span>
            </div>
            <div class="col-span-2 input">
              <Input
                label="Address Line 2"
                v-model:modelValue="address.address2"
                placeholder="Address Line 2"
              />
            </div>
            <!-- <div class="input">
              <Input
                label="Nickname"
                v-model:modelValue="address.nickname"
                placeholder="Nickname"
              />
            </div> -->

            <div class="input">
              <Input
                label="City"
                v-model:modelValue="address.city"
                placeholder="City"
              />
              <span
                v-if="isSubmit && v$.address.city.$silentErrors.length > 0"
                class="flex text-left text-sm font-light text-red-400 py-2"
              >
                <span
                  v-if="
                    v$.address.city.$silentErrors[0].$validator == 'required'
                  "
                  >City is required.</span
                >
              </span>
            </div>

            <div>
              <Listbox as="div" v-model="address.state" class="store-switcher">
                <div>
                  <ListboxLabel>State</ListboxLabel>
                  <ListboxButton class="group ss-btn">
                    <span class="flex w-full justify-between items-center">
                      <span
                        class="flex min-w-0 items-center justify-between space-x-3"
                      >
                        <div
                          class="flex items-center justify-center rounded-full w-6 h-6 md:w-8 md:h-8 text-brand-indigo bg-purple-100 flex-shrink-0 order-bg"
                        >
                          <Icon name="shop" class="w-5 h-5" />
                        </div>
                        <span
                          class="flex-1 flex flex-col min-w-0"
                          v-if="address.state"
                        >
                          <span
                            class="text-brand-secondary text-sm font-medium truncate"
                            >{{
                              address.state ? address.state.state_name : null
                            }}</span
                          >
                        </span>
                        <span
                          class="flex-1 flex flex-col min-w-0 leading-none space-y-1.5"
                          v-else
                        >
                          <div
                            class="h-3 font-medium rounded bg-gray-200 animate-pulse text-opacity-0 w-36"
                          >
                            <span class="opacity-0">XXXX XXXX</span>
                          </div>
                        </span>
                      </span>
                      <SelectorIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <ListboxOptions
                    class="item-opts"
                    style="height:110px; overflow:auto;"
                  >
                    <ListboxOption
                      as="div"
                      v-for="opt in st.data"
                      :key="opt.id"
                      :value="opt"
                      @click="selectZipcode(opt.state)"
                      class="group item-opt"
                    >
                      <span
                        class="flex min-w-0 items-center justify-between space-x-3"
                      >
                        <span class="flex-1 flex flex-col min-w-0">
                          <span class="name"
                            >{{ opt.state_name
                            }}{{ " (" + opt.state + ")" }}</span
                          >
                        </span>
                      </span>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </Listbox>
              <span
                v-if="isSubmit && v$.address.state.$silentErrors.length > 0"
                class="flex text-left text-sm font-light text-red-400 py-2"
              >
                <span
                  v-if="
                    v$.address.state.$silentErrors[0].$validator == 'required'
                  "
                  >State is required.</span
                >
              </span>
            </div>
            <div>
              <Combobox
                :disabled="!address.state"
                v-model="address.zip_code"
                class="store-switcher"
                nullable
              >
                <div class="relative mt-1">
                  <ComboboxLabel>Zip Code</ComboboxLabel>
                  <div
                    class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                  >
                    <ComboboxInput
                      :disabled="!address.state"
                      class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                      :displayValue="(zip) => zip?.zip_code"
                      @change="getZipcodes(state_code, $event.target.value)"
                    />
                    <ComboboxButton
                      class="absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </ComboboxButton>
                  </div>
                  <TransitionRoot
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    @after-leave="query = ''"
                  >
                    <ComboboxOptions
                      class="z-10 absolute max-h-28 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ComboboxOption
                        v-for="zip in zipcode.data.data"
                        as="template"
                        :key="zip.id"
                        :value="zip"
                        v-slot="{ selected, active }"
                      >
                        <li
                          class="relative cursor-default select-none py-2 pl-10 pr-4"
                          :class="{
                            'bg-teal-600 text-brand-indigo': active,
                            'text-gray-900': !active,
                          }"
                        >
                          <span
                            class="block truncate"
                            :class="{
                              'font-medium': selected,
                              'font-normal': !selected,
                            }"
                          >
                            {{ zip.zip_code }}
                          </span>
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3"
                            :class="{
                              'text-brand-indigo': active,
                              'text-teal-600': !active,
                            }"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>
                  </TransitionRoot>
                </div>
              </Combobox>
              <span
                v-if="isSubmit && v$.address.zip_code.$silentErrors.length > 0"
                class="flex text-left text-sm font-light text-red-400 py-2"
              >
                <span
                  v-if="
                    v$.address.zip_code.$silentErrors[0].$validator ==
                      'required'
                  "
                  >Zip code is required.</span
                >
                <span
                  v-else-if="
                    v$.address.zip_code.$silentErrors[0].$validator ==
                      'minLength'
                  "
                  >Zip code must be atleast 4 characters long.</span
                >
              </span>
            </div>
            <div class="col-span-2 flex justify-end">
              <div class="w-full md:flex-shrink-0" @click="save()">
                <Button title="Continue" :disabled="isSubmit" />
              </div>
            </div>
          </div>
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep === 2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-medium">
              Successfully Submitted
            </p>
            <span class="text-md text-brand-body"
              >Your new address is ready to use.</span
            >
          </div>
        </div>
      </Block>
      <Confirm
        :isVisible="showConfirmDialouge"
        cancelButton="Go Back"
        confirmButton="Yes"
        @cancel="showConfirmDialouge = false"
        @confirm="Yes"
      >
        <p>
          The address you entered does not appear to be a commercial address.
        </p>
        <p>
          Are you sure this is the correct address?
          <br />{{ address.address1 }}<br />
          {{ address.city }}, {{ address.state.state }}
          {{ address.zip_code.zip_code }}
        </p>
      </Confirm>
    </ModalFull>
    <!-- payments modal -->
    <ModalFull
      :is-visible="showPaymentsModal"
      :reset-container="true"
      @close="showPaymentsModal = isSubmit = false"
      container-class="w-full max-w-2xl"
    >
      <div class="w-full bg-white">
        <div
          class="flex items-center justify-between bg-gray-100 px-5 py-3 sm:p-5"
        >
          <h3 class="flex items-center text-base sm:text-lg font-medium">
            <Icon name="add-card" class="w-6 h-6 mr-2" />
            <span>Add New Card</span>
          </h3>
          <button
            @click="showPaymentsModal = isSubmit = false"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="close" class="w-5 h-5 mr-1" />
          </button>
        </div>
        <div class="grid grid-cols-5 w-full divide-x divide-gray-100">
          <div class="flex-grow col-span-5 md:col-span-3">
            <div class="flex flex-col items-start text-left space-y-4 p-5">
              <div class="w-full space-y-1">
                <div class="flex items-center w-full space-x-2">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Card Nickname (Optional)</label
                  >
                  <tippy interactive>
                    <span class="cursor-pointer">
                      <Icon
                        name="info"
                        class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                      />
                    </span>
                    <template #content>
                      <p class="text-left px-1.5 text-xs">
                        A nickname helps you remember what a specific credit
                        card is for. For example: Main Street Store Card, Jones
                        Street Store Card.
                      </p>
                    </template>
                  </tippy>
                </div>

                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.nickname"
                    name=""
                    @keyup="checkValue()"
                    id=""
                    maxlength="32"
                    class="placeholder:font-light placeholder:text-gray-400  text-md sm:text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent"
                  />
                </div>
              </div>

              <div class="w-full space-y-1">
                <label for="" class="text-xs text-theme-body leading-none"
                  >Card Number</label
                >
                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.cardNumber"
                    v-maska="generateCardNumberMask"
                    name=""
                    id=""
                    class="placeholder:font-light placeholder:text-gray-400  text-md sm:text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent"
                  />
                  <span
                    class="pointer-events-none h-4 absolute right-2 top-1/2 -mt-2 w-8"
                  >
                    <img
                      :src="
                        addPaymentForm.cardNumber[0] == 4
                          ? '/images/card-providers/visa.png'
                          : addPaymentForm.cardNumber[0] == 5
                          ? '/images/card-providers/master-card.png'
                          : addPaymentForm.cardNumber[0] == 6
                          ? '/images/card-providers/Discover-logo.png'
                          : addPaymentForm.cardNumber[0] == 3
                          ? '/images/card-providers/american-express.png'
                          : null
                      "
                      class="max-w-full"
                    />
                  </span>
                </div>
                <span
                  v-if="
                    isSubmit &&
                      v$.addPaymentForm.cardNumber.$silentErrors.length > 0
                  "
                  class="text-theme-red font-semibold text-xs"
                >
                  <span
                    v-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'required'
                    "
                    >Required</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'numeric'
                    "
                    >Only Number</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'maxLength'
                    "
                    >Only 16 digits</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'minLength'
                    "
                    >Only 15 digits</span
                  >
                </span>
              </div>
              <!-- <div class="grid grid-cols-2 gap-5"> -->

              <div class="w-full space-y-1">
                <label for="" class="text-xs text-theme-body leading-none"
                  >Name on card</label
                >
                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.name"
                    name=""
                    id=""
                    class="placeholder:font-light placeholder:text-gray-400  text-md sm:text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent"
                  />
                </div>
                <span
                  v-if="
                    isSubmit && v$.addPaymentForm.name.$silentErrors.length > 0
                  "
                  class="text-theme-red font-semibold text-xs"
                >
                  <span
                    v-if="
                      v$.addPaymentForm.name.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Required</span
                  >
                </span>
              </div>
              <!-- </div> -->
              <div class="grid grid-cols-3 gap-5">
                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Exp<span class="hidden sm:inline">iration</span>
                    Month</label
                  >
                  <div class="relative">
                    <!-- <input
                      type="text"
                      v-model="addPaymentForm.expMonth"
                      name=""
                      id=""
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                    /> -->
                    <select
                      name="expMonth"
                      id="expMonth"
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                      v-model="addPaymentForm.expMonth"
                    >
                      <option
                        :value="val"
                        v-for="(val, index) in expMonthsValues"
                        :key="val"
                        >{{ expMonths[index] }}</option
                      >
                    </select>
                  </div>
                  <span
                    v-if="
                      isSubmit &&
                        v$.addPaymentForm.expMonth.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.expMonth.$silentErrors[0]
                          .$validator == 'required'
                      "
                      >Required</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.expMonth.$silentErrors[0]
                          .$validator == 'minValue'
                      "
                      >Current Month</span
                    >
                  </span>
                </div>

                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Exp<span class="hidden sm:inline">iration</span>
                    Year</label
                  >
                  <div class="relative">
                    <!-- <input
                      type="text"
                      v-model="addPaymentForm.expYear"
                      name=""
                      id=""
                      class="placeholder:font-light placeholder:text-gray-400  text-md sm:text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent"
                    /> -->
                    <select
                      name="expYear"
                      id="expYear"
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                      v-model="addPaymentForm.expYear"
                    >
                      <option :value="exp" v-for="exp in expYears" :key="exp">{{
                        exp
                      }}</option>
                    </select>
                  </div>
                  <span
                    v-if="
                      isSubmit &&
                        v$.addPaymentForm.expYear.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.expYear.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Required</span
                    >
                  </span>
                </div>

                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >CVV</label
                  >
                  <div class="relative">
                    <input
                      type="text"
                      v-maska="generateCVVMask"
                      v-model="addPaymentForm.cvv"
                      name=""
                      id=""
                      class="placeholder:font-light placeholder:text-gray-400  text-md sm:text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent"
                    />
                  </div>
                  <span
                    v-if="
                      isSubmit && v$.addPaymentForm.cvv.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Required</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'numeric'
                      "
                      >Only Number</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'maxLength'
                      "
                      >Only 03 digits</span
                    >
                  </span>
                </div>
              </div>

              <div class="w-full space-y-1">
                <input
                  type="checkbox"
                  v-model="addPaymentForm.save_card"
                  name="savePayment"
                  id="savePayment"
                  class="h-4 w-4 border-2 border-brand-gray border-opacity-50 rounded text-brand-secondary focus:ring-transparent cursor-pointer"
                />
                <label
                  for="savePayment"
                  class="text-xs text-theme-body leading-none ml-2 cursor-pointer select-none"
                  >Save for future payments</label
                >
              </div>

              <div
                class="flex items-center space-x-3 bg-theme-bg-shade bg-opacity-20 px-3 py-2 rounded text-sm text-theme-body w-full"
              >
                <Icon name="lock" class="w-4 h-4" />
                <span>We never store your credit card information</span>
              </div>
            </div>
            <div
              @click="AddNewPaymentCard"
              class="flex items-center justify-between px-5 pt-3 pb-6"
            >
              <Button icon="right-arrow" title="Add Card" />
            </div>
          </div>
          <div
            class="hidden md:flex flex-col space-y-5 col-span-2 py-4 px-6 text-left text-sm"
          >
            <Icon name="user-sheild" class="w-12 h-12" />
            <h4 class="text-base font-semibold">Your information is safe</h4>
            <div class="space-y-3 text-theme-body-light">
              <p>
                We do not store your credit card information on our servers.
              </p>
              <p>
                Credit Card information is passed via a secure socket layer to
                our payment processor where your information is tokenized (a
                random number is generated to represent your card).
              </p>
              <p>
                No employee of Medusa has access to your complete credit card
                number.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalFull>

    <!-- Split Payments-->
    <ModalFull
      :is-visible="showSplitPayModal"
      :reset-container="true"
      container-class="w-full max-w-4xl "
      @close="showSplitPayModal"
    >
      <div class="w-full rounded-lg bg-white shadow-box">
        <div class="flex justify-between px-5 py-3 w-full">
          <div class="flex items-start justify-start space-x-3">
            <Icon
              name="split"
              class="w-5 h-5 sm:w-7 sm:h-7 mt-1 text-theme-purple-dark"
            />
            <div class="text-left text-base">
              <h3
                class="text-base sm:text-lg text-theme-secondary font-semibold"
              >
                Split your Payment
              </h3>
              <p class="text-theme-body font-medium text-xs sm:text-sm">
                Split the order total across 2 cards
              </p>
            </div>
          </div>
          <div>
            <button
              class="text-theme-secondary flex items-center justify-center mr-2 text-base font-semibold"
              v-if="currentTab === 1"
              @click="changeStep(0)"
            >
              <Icon name="left-arrow" class="w-5 h-5 mr-0.5" />
              Back
            </button>
            <div
              v-else
              class="close cursor-pointer hover:bg-sky-700  "
              @click="resetSplitCards"
            >
              <span>X</span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-2">
            <div
              :class="
                currentTab === 0
                  ? 'border-b-2 border-theme-body-light'
                  : 'border-b border-theme-border'
              "
            ></div>
            <div
              :class="
                currentTab === 1
                  ? 'border-b-2 border-theme-body-light'
                  : 'border-b border-theme-border'
              "
            ></div>
          </div>
          <div class="text-left">
            <div class="block w-full py-5 px-8" v-show="currentTab === 0">
              <div class="flex flex-col items-start">
                <span
                  class="rounded-full bg-theme-bg-shade text-xs text-theme-secondary py-0.5 px-4"
                  >STEP 1</span
                >
                <div
                  class="flex flex-col md:flex-row md:items-center justify-between w-full py-2 gap-2"
                >
                  <h3
                    class="text-sm md:text-base font-medium text-theme-secondary"
                  >
                    Select the first card and enter the amount to be charged
                  </h3>
                  <span
                    class="text-sm md:text-base text-theme-body-light font-medium"
                    >Order Total : ${{ currencyFormat(getGrandTotal()) }}</span
                  >
                </div>
                <div class="w-full py-3 sm:py-6">
                  <RadioGroup v-model="splitCards.opt1.card" class="w-full">
                    <div
                      class="flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6 cards-list"
                      v-if="cards.data.length > 0 && !cards.loading"
                    >
                      <RadioGroupOption
                        as="template"
                        v-for="card in cards.data"
                        :key="card.name"
                        :value="card"
                        v-slot="{ checked }"
                      >
                        <div class="checkblock">
                          <div class="flex w-full justify-end -mb-6 md:-mb-4">
                            <div
                              :class="[
                                checked
                                  ? ' bg-theme-indigo border-theme-indigo text-white'
                                  : 'bg-white border-theme-border ',
                                'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                              ]"
                            >
                              <svg
                                v-if="checked"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="block">
                            <RadioGroupLabel
                              as="span"
                              v-if="card.nickname"
                              class="item-nickname"
                              >{{ card.nickname }}</RadioGroupLabel
                            >
                            <RadioGroupLabel as="p" class="item-name">{{
                              card.cc_name
                            }}</RadioGroupLabel>
                            <div
                              class="flex items-center gap-2 md:flex-col md:gap-4 md:items-start"
                            >
                              <RadioGroupDescription
                                as="div"
                                class="item-desc no-margin"
                                >{{
                                  "xxxx-xxxx-xxxx-" + card.cc_number.slice(-4)
                                }}</RadioGroupDescription
                              >
                              <div
                                class="flex items-center justify-between gap-5"
                              >
                                <RadioGroupDescription
                                  as="span"
                                  class=" text-theme-dark text-xs md:text-sm font-normal"
                                  >{{ card.cc_exp.slice(0, 2) }}/{{
                                    card.cc_exp.slice(-2)
                                  }}</RadioGroupDescription
                                >
                                <div
                                  class="w-8 h-7 flex items-center justify-end"
                                >
                                  <img
                                    :src="
                                      card.cc_number[0] == 4
                                        ? '/images/card-providers/visa.png'
                                        : card.cc_number[0] == 5
                                        ? '/images/card-providers/master-card.png'
                                        : card.cc_number[0] == 6
                                        ? '/images/card-providers/Discover-logo.png'
                                        : card.cc_number[0] == 3
                                        ? '/images/card-providers/american-express.png'
                                        : ''
                                    "
                                    class="max-w-full"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            :class="[
                              checked
                                ? 'border-theme-indigo border-opacity-50'
                                : 'border-transparent',
                              'absolute -inset-px rounded-lg border-2 pointer-events-none',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>
                <div
                  class="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 w-full"
                >
                  <input
                    v-model="splitCards.opt1.amount"
                    min="0"
                    :max="getGrandTotal()"
                    @input="onChangeSplitInput(splitCards.opt1.amount)"
                    type="number"
                    class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 px-4 py-2.5 leading-none placeholder-theme-bg-shade sm:w-60 focus:border-theme-body-light focus:ring-0 w-full"
                    placeholder="Enter Amount"
                  />
                  <div class="grid grid-cols-3 sm:flex sm:items-center gap-4">
                    <button
                      @click="setSplitCardAmount(25, getGrandTotal())"
                      class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                    >
                      25%
                    </button>
                    <button
                      @click="setSplitCardAmount(50, getGrandTotal())"
                      class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                    >
                      50%
                    </button>
                    <button
                      @click="setSplitCardAmount(75, getGrandTotal())"
                      class="bg-theme-bg rounded-md border border-theme-border border-opacity-50 text-sm text-theme-secondary text-opacity-90 py-1.5 px-2"
                    >
                      75%
                    </button>
                  </div>
                </div>
                <div
                  class="flex w-full items-center justify-center md:justify-between mt-5 md:mt-10 py-2"
                >
                  <p
                    class="text-base sm:text-lg text-theme-secondary font-semibold"
                  ></p>
                  <button
                    :class="[
                      splitCards.opt1.card && splitCards.opt1.amount
                        ? 'hover:bg-opacity-90 bg-theme-secondary text-white'
                        : 'bg-theme-body bg-opacity-40 opacity-70 cursor-default',
                      'w-60 font-medium text-center py-2.5 rounded-md',
                    ]"
                    :disabled="
                      splitLoading ||
                        !(splitCards.opt1.card && splitCards.opt1.amount)
                    "
                    @click="
                      splitCards.opt1.card &&
                      splitCards.opt1.amount &&
                      !splitLoading
                        ? nextCardStepOne(1, splitCards.opt1)
                        : ''
                    "
                  >
                    <Spinner className="w-5 h-5" :loading="splitLoading" />
                    <span v-if="!splitLoading">Next Card</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="block w-full py-5 px-8" v-show="currentTab === 1">
              <div class="flex flex-col items-start">
                <span
                  class="rounded-full bg-theme-bg-shade text-xs text-theme-secondary py-0.5 px-4"
                  >STEP 2</span
                >
                <div
                  class="flex flex-col md:flex-row md:items-center justify-between w-full py-2 gap-2"
                >
                  <h3
                    class="text-sm md:text-base font-medium text-theme-secondary"
                  >
                    Choose card for the remaining ${{
                      currencyFormat(getGrandTotal() - splitCards.opt1.amount)
                    }}
                  </h3>
                  <span
                    class="text-sm md:text-base text-theme-body-light font-medium"
                    >Order Total : ${{ currencyFormat(getGrandTotal()) }}</span
                  >
                </div>
                <div class="w-full py-3 sm:py-6">
                  <RadioGroup v-model="splitCards.opt2.card" class="w-full">
                    <RadioGroupLabel class="sr-only"
                      >Payment Method</RadioGroupLabel
                    >
                    <div
                      class="flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-x-6 md:gap-y-12 cards-list"
                      v-if="cards.data.length > 0 && !cards.loading"
                    >
                      <!-- @click="finishSelection(checkoutData.data.gTotal - splitCards.opt1.amount,{card})" -->

                      <RadioGroupOption
                        as="template"
                        v-for="card in cards.data"
                        :key="card.name"
                        :value="card"
                        v-slot="{ checked }"
                        :class="{
                          disabled:
                            splitCards.opt1.card &&
                            card.id == splitCards.opt1.card.id,
                        }"
                      >
                        <div
                          class="checkblock"
                          :class="{
                            'has-label':
                              (splitCards.opt1.card &&
                                card.id == splitCards.opt1.card.id) ||
                              (splitCards.opt2.card &&
                                card.id == splitCards.opt2.card.id),
                          }"
                        >
                          <div class="flex w-full justify-end -mb-6 md:-mb-4">
                            <div
                              :class="[
                                splitCards.opt1.card &&
                                card.id == splitCards.opt1.card.id
                                  ? 'bg-theme-border border-theme-border opacity-50 text-white'
                                  : checked
                                  ? ' bg-theme-indigo border-theme-indigo text-white'
                                  : 'bg-white border-theme-border ',
                                'border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center',
                              ]"
                            >
                              <svg
                                v-if="
                                  checked ||
                                    (splitCards.opt1.card &&
                                      card.id == splitCards.opt1.card.id)
                                "
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="block">
                            <RadioGroupLabel
                              as="span"
                              v-if="card.nickname"
                              class="item-nickname"
                              >{{ card.nickname }}</RadioGroupLabel
                            >
                            <RadioGroupLabel as="p" class="item-name">{{
                              card.cc_name
                            }}</RadioGroupLabel>
                            <div
                              class="flex items-center gap-2 md:flex-col md:gap-4 md:items-start"
                            >
                              <RadioGroupDescription
                                as="div"
                                class="item-desc no-margin"
                                >{{
                                  "xxxx-xxxx-xxxx-" + card.cc_number.slice(-4)
                                }}</RadioGroupDescription
                              >
                              <div
                                class="flex items-center justify-between gap-5"
                              >
                                <RadioGroupDescription
                                  as="span"
                                  class=" text-theme-dark text-xs md:text-sm font-normal"
                                  >{{ card.cc_exp.slice(0, 2) }}/{{
                                    card.cc_exp.slice(-2)
                                  }}</RadioGroupDescription
                                >
                                <div
                                  class="w-8 h-7 flex items-center justify-end"
                                >
                                  <img
                                    :src="
                                      card.cc_number[0] == 4
                                        ? '/images/card-providers/visa.png'
                                        : card.cc_number[0] == 5
                                        ? '/images/card-providers/master-card.png'
                                        : card.cc_number[0] == 6
                                        ? '/images/card-providers/Discover-logo.png'
                                        : card.cc_number[0] == 3
                                        ? '/images/card-providers/american-express.png'
                                        : ''
                                    "
                                    class="max-w-full"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            :class="[
                              checked
                                ? 'border-theme-indigo border-opacity-50'
                                : 'border-transparent',
                              'absolute -inset-px rounded-lg border-2 pointer-events-none z-20',
                            ]"
                            aria-hidden="true"
                          />

                          <p
                            class="text-xs flex items-start leading-none gap-1 bg-theme-purple-dark bg-opacity-30 px-2.5 py-1.5 absolute top-full rounded-b-md text-theme-secondary font-medium z-0 left-0 right-0"
                            v-if="
                              splitCards.opt1.card &&
                                card.id == splitCards.opt1.card.id
                            "
                          >
                            ${{ currencyFormat(splitCards.opt1.amount) }}
                            to be billed to this card
                          </p>
                          <p
                            class="text-xs flex items-start leading-none gap-1 bg-theme-purple-dark bg-opacity-30 px-2.5 py-1.5 absolute top-full rounded-b-md text-theme-secondary font-medium z-0 left-0 right-0"
                            v-if="
                              splitCards.opt2.card &&
                                card.id == splitCards.opt2.card.id
                            "
                          >
                            ${{
                              currencyFormat(
                                getGrandTotal() - splitCards.opt1.amount
                              )
                            }}
                            to be billed to this card
                          </p>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>

                <div
                  class="flex w-full items-center justify-center md:justify-between mt-5 md:mt-10 py-2"
                >
                  <p class="text-lg text-theme-secondary font-semibold">
                    <!-- ${{
                      currencyFormat(getGrandTotal() - splitCards.opt1.amount)
                    }}
                    <span class="opacity-70">will be charged to this card</span> -->
                  </p>
                  <button
                    :class="[
                      splitCards.opt2.card ? 'hover:bg-opacity-90' : '',
                      splitCards.opt2.card
                        ? 'bg-theme-secondary text-white'
                        : 'bg-theme-body bg-opacity-40',
                      'w-60 font-semibold text-center py-2.5 rounded-md',
                    ]"
                    @click="
                      splitCards.opt2.card
                        ? finishSelection(
                            getGrandTotal() - splitCards.opt1.amount,
                            splitCards.opt2
                          )
                        : ''
                    "
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Split payment warning modal -->
      <Confirm
        :isVisible="splitPaymentWarning"
        cancelButton="Cancel"
        confirmButton="Yes"
        :title="'Notice'"
        @cancel="splitPaymentWarning = false"
        @confirm="splitConfirm"
      >
        <p
          v-text="
            'You\'ve chosen to use one card to pay the full amount. Are you sure?'
          "
        ></p>
      </Confirm>
    </ModalFull>
    <!--  -->

    <!-- Codnrim modal -->
    <Confirm
      :isVisible="isWarning"
      cancelButton="Cancel"
      confirmButton="Yes"
      @cancel="isWarning = false"
      @confirm="isWarning = false"
    >
      <p>
        {{ warnMsg }}
      </p>
    </Confirm>
    <!--  Confrim Modal -->

    <!-- Codnrim modal -->
    <Confirm
      :isVisible="confirm"
      cancelButton="Cancel"
      confirmButton="Yes"
      @cancel="confirm = false"
      @confirm="confirmToDelete"
    >
      <p>
        Are you sure you want to remove this credit card ending in
        {{ this.selectedCard.cc_number.slice(-4) }}?"
      </p>
    </Confirm>
    <!--  Confrim Modal -->

    <!-- Removable Notice -->
    <NoticeModal
      :is-visible="isItemsNoticeVisible"
      :items="removeableItems"
      @close="isItemsNoticeVisible = !isItemsNoticeVisible"
      @confirm="removeItemsFn"
    />

    <InventoryNoticeModal
      :is-visible="isItemsInventoryNoticeVisible"
      :items="removeableItems"
      @close="isItemsInventoryNoticeVisible = !isItemsInventoryNoticeVisible"
    />

    <CheckoutErrorModal
      :type="'non-avs'"
      :is-visible="isPaymentDeclined"
      :description="errorDesc"
      @close="isPaymentDeclined = !isPaymentDeclined"
    />

    <CheckoutErrorModal
      :is-visible="hasExpiredCards"
      title="Credit Card Expiration"
      description="The following saved credit card(s) have expired and will be removed from your account:"
      @close="deleteExpiredCards()"
    >
      <div class="flex flex-col gap-2 py-4 pr-5">
        <div
          class="flex items-center justify-between"
          v-for="card in expiredCards"
          :key="card.cc_number"
        >
          <div class="flex items-center gap-2">
            <div class="w-5 h-6 flex items-center justify-end">
              <img
                :src="
                  card.cc_number[0] == 4
                    ? '/images/card-providers/visa.png'
                    : card.cc_number[0] == 5
                    ? '/images/card-providers/master-card.png'
                    : card.cc_number[0] == 6
                    ? '/images/card-providers/Discover-logo.png'
                    : card.cc_number[0] == 3
                    ? '/images/card-providers/american-express.png'
                    : ''
                "
                class="max-w-full"
              />
            </div>
            <p class="text-md font-medium text-theme-secondary text-opacity-60">
              {{ card.cc_name }}
            </p>
          </div>
          <div class="flex items-center justify-end gap-4">
            <span class=" text-theme-dark text-sm font-normal">{{
              "X-" + card.cc_number.slice(-4)
            }}</span>
            <span class=" text-theme-red text-sm font-normal"
              >{{ card.cc_exp.slice(0, 2) }}/{{ card.cc_exp.slice(-2) }}</span
            >
          </div>
        </div>
      </div>
    </CheckoutErrorModal>
  </div>
</template>

<script>
import {
  DotsVerticalIcon,
  XIcon,
  SelectorIcon,
  ChevronLeftIcon,
  CheckIcon,
} from "@heroicons/vue/solid";
import Mymixin from "@/modules/mixins";
import Block from "./modules/Block.vue";
import { ref, inject, computed, watch } from "vue";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxLabel,
  Combobox,
  ComboboxInput,
  ComboboxLabel,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import Input from "@/components/Input.vue";
import Account from "@/modules/Account";
import Address from "@/modules/Address";
import Checkout from "@/modules/Checkout";
import Toast from "@/components/Toast.vue";
import {
  queryAutocompleteForSuggestions,
  selectSuggestion,
  formatSuggestion,
} from "@/modules/AutocompleteAddress";
import OrderSummary from "./modules/OrderSummary.vue";
import TaxSummary from "./modules/TaxSummary.vue";
import OrderItems from "./modules/OrderItems.vue";
import NumberInput from "../../../components/NumberInput.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  numeric,
  minLength,
  maxLength,
  minValue,
} from "@vuelidate/validators";
import NoticeModal from "./modules/NoticeModal.vue";
import InventoryNoticeModal from "./modules/InventoryNoticeModal.vue";
import Segments from "@/modules/Segments";
import CheckoutErrorModal from "./modules/CheckoutErrorModal.vue";
import ShippingOption from "./modules/ShippingOption.vue";
const { TrackCheckoutCompleted, CoinsEarn, CoinsRedeemed } = Segments();

const paylaterOptions = [
  {
    id: 1,
    name: "Pay Later",
    value: "pay later",
    provider: "/images/card-providers/medusa.png",
    desc: null,
  },
  {
    id: 2,
    name: "Pay At Pick Up",
    value: "pay at pickup",
    provider: "/images/card-providers/medusa.png",
    desc: "For Local Pickup Only",
  },
];
const bankAndZelle = [
  {
    id: 3,
    name: "Zelle",
    value: "zelle",
    provider: "/images/card-providers/zelle.png",
    desc: "Pay before shipment",
  },
  {
    id: 4,
    name: "Bank Transfer",
    value: "bank",
    provider: "/images/card-providers/bank.jpg",
    desc: "Pay before shipment",
  },
  {
    id: 5,
    name: "Pay Later",
    value: "pay-later",
    provider: "/images/card-providers/medusa.png",
    desc: "We'll contact you for payment",
  },
];

export default {
  name: "Checkout",
  mixins: [Mymixin],
  components: {
    Toast,
    Block,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    Input,
    OrderSummary,
    TaxSummary,
    OrderItems,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ListboxLabel,
    Combobox,
    ComboboxInput,
    ComboboxLabel,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    XIcon,
    SelectorIcon,
    CheckIcon,
    // NumberInput,
    NoticeModal,
    InventoryNoticeModal,
    CheckoutErrorModal,
    ShippingOption,
  },
  methods: {
    queryAutocompleteForSuggestions,
    selectSuggestion,
    formatSuggestion,
    show() {
      this.showAddressModal = true;
      this.currentStep = 1;
    },

    // save reference number after a second when user stops typing
    saveRefNumber() {
      this.ref_loading = true;
      if (this.refNumberTimeout) {
        clearTimeout(this.refNumberTimeout);
      }
      this.refNumberTimeout = setTimeout(() => {
        this.axios.authApi
          .post("/ref-number", {
            ref_number: this.checkoutData.data.refNumber,
          })
          .then((_) => (this.ref_loading = false));
      }, 1000);
    },

    checkValue() {
      this.addPaymentForm["nickname"] = this.addPaymentForm["nickname"].replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
    },
    checkValueStoreCredit() {
      if (this.store_credit < 0) return (this.store_credit = 0.0);
      let g_total = this.checkoutData.data.gTotal;
      if (this.checkoutData.appliedCoins) {
        g_total = g_total - this.checkoutData.appliedCoins.discount;
      }
      if (
        this.store_credit > this.checkoutData.data.storeCredit ||
        this.store_credit > g_total
      ) {
        this.store_credit = this.checkoutData.data.storeCredit;
        if (this.store_credit > g_total) {
          this.store_credit = g_total;
        }
      }
    },
    isCommercial() {
      fetch(
        "https://us-street.api.smartystreets.com/street-address?street=" +
          this.address.address1 +
          "&city=" +
          this.address.city +
          "&state=" +
          this.address.state.state +
          "&zipCode=" +
          this.address.zip_code.zip_code +
          "&key=115379242330163169&candidates=10&match=invalid"
      )
        .then((response) => response.json())
        .then((data) => (this.totalVuePackages = data[0].metadata.rdi));
    },
    addbillingAddress() {
      this.axios.authApi
        .post("/account/createAddress", {
          address1: this.address.address1,
          address2: this.address.address2,
          city: this.address.city,
          nickname: this.address.nickname,
          state: this.address.state ? this.address.state.state : null,
          zip_code: this.address.zip_code
            ? this.address.zip_code.zip_code
            : null,
          label: "Billing",
        })
        .then((resp) => {
          if (resp.data.status) {
            this.address = resp.data;
            this.currentStep = 2;
            this.isSubmit = false;
            this.getAddresses();
            this.address = {
              address1: "",
              address2: "",
              city: "",
              state: "",
            };
            var interval = setInterval(() => {
              this.addresses?.data?.forEach((address) => {
                if (address.id == resp.data.data.address_id) {
                  this.billingId = address.trade_gecko_id;
                  if (this.selectedShippingDelivery) {
                    this.selectShippingMethod(this.selectedShippingDelivery);
                  }
                  clearInterval(interval);
                }
              });
            }, 100);
            // this.reset();
          } else {
            this.address.error = resp.data.error;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectZipcode(state) {
      this.state_code = state;
      this.getZipcodes(state);
    },
    Yes() {
      this.addbillingAddress();
      this.isSubmit = false;
      this.currentStep = 2;
      this.showConfirmDialouge = false;
    },
    save() {
      this.isSubmit = true;
      this.v$.$validate("address");
      if (!this.v$.address.$silentErrors.length > 0) {
        // this.isCommercial();
        // if(this.totalVuePackages != 'Commercial')
        // {
        //   this.showConfirmDialouge = true;
        // }
        // else{
        this.addbillingAddress();
        // this.currentStep = 2;
        // this.isSubmit = false;
        // }
      } else {
        //  alert("Form nOT Valid");
      }
    },

    deleteExpiredCards() {
      this.hasExpiredCards = !this.hasExpiredCards;

      this.axios.authApi.post("/deleteAllExpiredCards").then((res) => {
        this.expiredCards = [];
        this.getCreditCards();
      });
    },
    checkingMonth() {
      return new Date().getFullYear() == this.addPaymentForm.expYear
        ? new Date().getMonth()
        : 1;
    },
    openSplitPayModal() {
      this.showSplitPayModal = true;
      this.selectedPayment = null;
      this.selectedCreditCard = null;
    },
    onChangeSplitInput(v) {
      if (v > 0) {
        this.splitCards.opt1.amount =
          v < this.getGrandTotal()
            ? Number(v.toFixed(2))
            : Number(this.getGrandTotal().toFixed(2));
      } else {
        this.splitCards.opt1.amount = "";
      }
    },
    setSplitCardAmount(per, total) {
      this.splitCards.opt1.amount = ((total * per) / 100).toFixed(2);
    },
    finishSelection(v, cardTwo) {
      this.splitCards.opt2.amount = v;
      this.selectedCreditCard = cardTwo.card.id;
      // let data = {
      //   billing_address_id: this.billingId,
      //   shipping_address_id: this.shippingId,
      //   shipping: this.shipping,
      //   user_saved: cardTwo.card.id,
      //   amount: v,
      //   // process_data :this.process_data
      // };
      // this.nmiStepOne(data, 1);
      this.currentTab = 0;
      this.selectedPayment = null;
      this.showSplitPayModal = false;
    },
    resetSplitCards() {
      this.selectedPayment = null;
      this.selectedCreditCard = null;
      this.splitCards = {
        opt1: {
          card: null,
          amount: null,
        },
        opt2: {
          card: null,
          amount: null,
        },
      };
      this.showSplitPayModal = false;
    },
    getGrandTotal() {
      let temp = this.checkoutData.data;
      let store = temp.appliedStoreCredit
        ? parseFloat(temp.appliedStoreCredit.value)
        : 0;
      let coins = this.checkoutData.appliedCoins
        ? parseFloat(this.checkoutData.appliedCoins.discount)
        : 0;
      let total = temp.gTotal - (store + coins);
      return total;
    },
    openPaymentModal() {
      this.cardErrorMsg = "";
      // this.axios.authApi
      //   .get("/customerVaultStepOne")
      //   .then((r) => (this.form_URL = r.data.data["form-url"]));
      this.showPaymentsModal = true;
    },
    AddNewPaymentCard() {
      this.isSubmit = true;
      this.v$.$validate("addPaymentForm");
      if (!this.v$.addPaymentForm.$error) {
        let dataToPost = {
          ccname: this.addPaymentForm.name,
          nickname: this.addPaymentForm.nickname,
          ccnumber: this.addPaymentForm.cardNumber,
          cvv: this.addPaymentForm.cvv,
          ccexp: this.addPaymentForm.expMonth + this.addPaymentForm.expYear,
        };
        this.cards.loading = true;
        this.axios.authApi.post("/customerVault", dataToPost).then((r) => {
          if (r.data.status) {
            this.getCreditCards();
            this.isSubmit = false;
          } else {
            this.cards.loading = false;
            this.cardErrorMsg = r.data.error;
          }
        });

        this.showPaymentsModal = false;
        this.addPaymentForm = {
          cardNumber: "",
          name: "",
          nickname: "",
          expMonth: "",
          expYear: "",
          cvv: "",
        };
      }
    },
    selectAddress(address, type) {
      if (this.shippingId != address.trade_gecko_id && type == "Shipping") {
        this.shippingId = address.trade_gecko_id;
        this.getShippingMethods(address.trade_gecko_id);
      }
      if (this.billingId != address.trade_gecko_id && type == "Billing") {
        this.billingId = address.trade_gecko_id;

        let data = {
          billing_address_id: this.billingId,
          shipping_address_id: this.shippingId,
          shipping: this.shipping,
        };
        this.nmiStepOne(data);
      }
    },

    selectShippingMethod(shipping) {
      this.removeCards();
      this.selectedShippingDelivery = shipping;
      this.shipping = shipping.fullCode;
      let data = {
        billing_address_id: this.billingId,
        shipping_address_id: this.shippingId,
        shipping: shipping.fullCode,
      };
      this.nmiStepOne(data);
    },
    removeCard(card) {
      this.confirm = true;
      this.selectedCard = card;
    },
    confirmToDelete() {
      this.axios.authApi
        .get("/deleteCard/" + this.selectedCard.id)
        .then((r) => {
          this.getCreditCards();
          this.confirm = this.isSubmit = false;
        });
    },
    nextCardStepOne(step, cardOne) {
      if (cardOne.amount < this.getGrandTotal()) {
        this.selectedPayment = cardOne.card.id;
        this.currentTab = step;
        this.splitLoading = false;
      } else {
        // When user entered amount is equal to total amount
        this.splitPaymentWarning = true;
        this.selectedCreditCard = cardOne.card.id;
      }
    },

    splitConfirm() {
      this.splitPaymentWarning = false;
      this.selectedPayment = null;
      this.showSplitPayModal = false;
    },

    async applyCoupon() {
      this.couponClicked = true;
      // console.log("Coupon")
      if (this.coupon) {
        this.couponClicked = true;
        // if (this.checkoutData.data.coupons.length == 0) {
        await this.couponApply(this.coupon);
        this.couponClicked = false;
        // }
      } else {
        this.$store.dispatch("setToast", {
          status: true,
          msg: "Enter Discount Code!",
          type: "danger",
        });
      }
    },

    removeCoupon(id) {
      this.couponRemove(id);
    },

    removePoint() {
      this.pointRemove();
      this.discountCoins = false;
      this.coinsValue = "";
    },
    appliedPoints(coin) {
      if (this.coinsValue == coin.value) {
        this.removePoint();
      } else {
        this.coinsValue = coin.value;
        this.applyCoins(coin);
      }
    },

    applyStoreCredit(v) {
      if (v <= 0) return;
      if (v <= this.checkoutData.data.storeCredit) {
        this.checkoutData.data.appliedStoreCredit = {
          label: "Store Credit",
          value: v,
        };
        this.checkoutData.data.canApplyStoreCredit = false;
        // this.creditApply();
      } else {
        this.checkoutData.data.appliedStoreCredit = {
          label: "Store Credit",
          value: this.checkoutData.data.storeCredit,
        };
        this.checkoutData.data.canApplyStoreCredit = false;
        this.store_credit = this.checkoutData.data.storeCredit;
      }
    },
    removeStoreCredit() {
      this.checkoutData.data.appliedStoreCredit = null;
      this.checkoutData.data.canApplyStoreCredit = true;
      this.store_credit = 0.0;
    },
    selectCreditCard(id, cardNum, isExpired) {
      if (isExpired) {
        return;
      }
      if (id == this.selectedCreditCard) {
        this.selectedPayment = null;
        this.cardlogo = null;
        this.selectedCreditCard = null;
        this.selectedCardNum = null;
        return;
      }
      this.cardlogo = cardNum;
      this.selectedCreditCard = id ? id : 1;
      this.selectedCardNum = "xxxx-xxxx-xxxx-" + cardNum.slice(-4);
      let data = {
        billing_address_id: this.billingId,
        shipping_address_id: this.shippingId,
        shipping: this.shipping,
      };
      if (id) data.user_saved = id;
      else data.saved_user = 1;
      // this.nmiStepOne(data, 1);
    },
    selectPayLaterOption(item) {
      let id = this.selectedCreditCard ? this.selectedCreditCard.id : "";
      this.selectedCardNum = null;
      if (item.id == id) {
        this.selectedPayment = null;
        this.selectedPaylater = null;
        this.selectedCreditCard = null;
        return;
      }
      item.type = "payment_terms";
      this.selectedCreditCard = item;
    },
    removeCards() {
      this.selectedCreditCard = null;
      this.selectedPayment = null;
      this.selectedPaylater = null;
      this.cardlogo = null;
      this.selectedCardNum = null;
    },
    showSnackbar(type, text) {
      this.$snackbar.add({
        type: type,
        text: text,
        dismissible: false,
        icon: {},
      });
    },
    payment() {
      // console.log(this.selectedCreditCard);
      if (
        (this.selectedCreditCard &&
          this.selectedCreditCard.type == "payment_terms") ||
        this.getGrandTotal() == 0
      ) {
        this.isPayNow = true;
        this.showSnackbar(
          "success",
          "Please wait! Your order is processing..."
        );
        this.checkoutData.data.reward = {};
        let data = {
          payment_terms: this.selectedCreditCard?.value,
          refNumber: this.checkoutData.data.refNumber,
        };
        if (this.checkoutData.data.appliedStoreCredit)
          data["storeCredit"] = this.store_credit;

        if (this.checkoutData.data.coins_earned)
          CoinsEarn(this.checkoutData.data.coins_earned);

        if (this.checkoutData.appliedCoins) {
          data["appliedPoints"] = this.checkoutData.appliedCoins.value;
          this.checkoutData.data.reward.name =
            "$" +
            this.checkoutData.appliedCoins.discount +
            " Off An Order - " +
            this.checkoutData.appliedCoins.points +
            " Medusa Coins";
          this.checkoutData.data.reward.points_redeemed = this.checkoutData.appliedCoins.points;
          this.checkoutData.data.reward.discount = this.checkoutData.appliedCoins.discount;
          CoinsRedeemed(this.checkoutData.appliedCoins.points);
        }

        this.checkoutData.data.shipping_method = this.selectedShippingDelivery.title;
        this.checkoutData.data.payment_method = this.selectedCreditCard?.name;
        this.axios.authApi
          .post("/payLater", data)
          .then((res) => {
            if (res.data.status == "stock_error") {
              this.isItemsInventoryNoticeVisible = true;
              this.removeableItems = res.data.data;
            } else if (res.data.status == true) {
              // TrackCheckoutCompleted(this.checkoutData.data);
              this.$router.push(`/thankyou/${res.data.order_id}`);
            } else {
              this.isPaymentError(res.data.error);
            }
          })
          .finally(() => (this.isPayNow = false));
        return;
      } else if (this.selectedCreditCard) {
        this.isPayNow = true;
        this.showSnackbar(
          "success",
          "Please wait! Your payment is processing..."
        );
        let process_data = this.process_data;
        let data = {
          refNumber: this.checkoutData.data.refNumber,
          customer_vault_id: this.selectedCreditCard,
          process_data,
          storeCredit: this.checkoutData.data.appliedStoreCredit
            ? this.store_credit
            : null,
        };
        if (this.splitCards.opt1.card && this.splitCards.opt2.card) {
          data["split_cards"] = [
            {
              card_id: this.splitCards.opt1.card.id,
              amount: this.splitCards.opt1.amount,
            },
            {
              card_id: this.splitCards.opt2.card.id,
              amount: this.splitCards.opt2.amount,
            },
          ];
        }
        this.checkoutData.data.shipping_method = this.selectedShippingDelivery.title;
        this.nmiStepTwo(data, this.isPaymentError, this.isStockError);
      }
    },
    isStockError(e) {
      this.isItemsInventoryNoticeVisible = true;
      this.removeableItems = e.data;
      this.isPayNow = false;
    },
    isPaymentError(m) {
      this.isPaymentDeclined = true;
      this.isPayNow = false;
      if (typeof m == "object")
        this.errorDesc =
          "Your Card *****" + m?.card?.slice(-4) + " was declined";
      else if (m != "AVS REJECTED") this.errorType = "non-avs";
      // this.warnMsg = m;
      // this.isWarning = true;
    },

    sortItems(data) {
      return data
        ? data.sort((a, b) => a.productName.localeCompare(b.productName))
        : [];
    },

    getTotal(data) {
      let temp = 0;
      if (data)
        data.forEach((element) => (temp += element.qty * element.singlePrice));
      return temp;
    },

    coinsEarned() {
      if (this.checkoutData.data.canApplyPoints) {
        let diff = this.checkoutData.data.coins_earned;
        if (this.checkoutData.appliedCoins) {
          diff = diff - this.checkoutData.appliedCoins.discount;
        }
        if (this.checkoutData.data.appliedStoreCredit) {
          diff = diff - this.checkoutData.data.appliedStoreCredit.value;
        }
        return diff > 0 ? diff : 0;
      }
    },
    getData() {
      if (this.checkoutData.data.payLater == 1) {
        if (this.checkoutData.data.IsUSA == 0) {
          return this.bankAndZelle.filter((e) => e.value == "bank");
        }
        if (this.checkoutData.data.collectTax == 0) {
          return this.bankAndZelle.filter((e) => e.value != "pay-later");
        }
        return this.bankAndZelle;
      }
      return [];
    },
    getDiscount() {
      let coins = this.checkoutData.appliedCoins
        ? parseFloat(this.checkoutData.appliedCoins.discount)
        : 0;
      let store = this.checkoutData.data.appliedStoreCredit
        ? parseFloat(this.checkoutData.data.appliedStoreCredit.value)
        : 0;
      return store + coins;
    },

    checkIfAddressNotFound() {
      if (this.address.loading == false && this.checkoutData.data.shipping_id) {
        let filteredData = this.addresses.data.filter(
          (e) => e.trade_gecko_id == this.checkoutData.data.shipping_id
        );
        if (filteredData.length == 0) {
          this.$router.push("/home");
        }
      }
    },
  },
  computed: {
    getCardType() {
      let number = this.addPaymentForm.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? "#### ###### #####"
        : "#### #### #### ####";
    },
    generateCVVMask() {
      return this.getCardType === "amex" ? "####" : "###";
    },
    shippingId: {
      get() {
        if (this.isSameBilling && this.checkoutData.data.shipping_id) {
          this.selectAddress(
            { trade_gecko_id: this.checkoutData.data.shipping_id },
            "Billing"
          );
        }
        return this.checkoutData.data.shipping_id;
      },
      set(newValue) {
        this.checkoutData.data.shipping_id = newValue;
      },
    },
    billingId: {
      get() {
        return this.checkoutData.data.billing_id;
      },
      set(newValue) {
        this.checkoutData.data.billing_id = newValue;
      },
    },
    shipTo: {
      get() {
        return this.addresses.data.find(
          (address) =>
            address.trade_gecko_id == this.checkoutData.data.shipping_id
        );
      },
    },
    billTo: {
      get() {
        return this.addresses.data.find(
          (address) =>
            address.trade_gecko_id == this.checkoutData.data.billing_id
        );
      },
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      form_URL: "",
      process_data: "",
      addPaymentForm: {
        cardNumber: "",
        name: "",
        expMonth: "",
        expYear: "",
        cvv: "",
        save_card: false,
      },
      splitLoading: false,
      splitCards: {
        opt1: {
          card: null,
          amount: null,
        },
        opt2: {
          card: null,
          amount: null,
        },
      },
      store_credit: 0.0,
      coinsValue: "",
      cardErrorMsg: "",
      isSubmit: false,
      cardlogo: undefined,
      selectedCardNum: undefined,
      selectedCreditCard: undefined,
      selectedShippingDelivery: undefined,
      shipping: undefined,
      suggestions: [],
      coupon: "",
      isPayNow: false,
      isWarning: false,
      confirm: false,
      selectedCard: false,
      warnMsg: "",
      expMonths: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      expMonthsValues: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      showConfirmDialouge: false,
      totalVuePackages: null,
      state_code: "",
      newAddressId: "",
      errorType: "avs",
      enableRef: false,
      refNumberTimeout: null,
      ref_loading: false,
      splitPaymentWarning: false,
      errorDesc: "The credit card payment was not successful.",
      couponClicked: false,
    };
  },
  // beforeMount() {
  //   this.getShippingMethods();
  // },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      this.$store.dispatch("setFromCheckOut", true);
    }
    next();
  },

  validations() {
    return {
      addPaymentForm: {
        cardNumber: {
          required,
          // numeric,
          // minLength: minLength(18),
          // maxLength: maxLength(19),
        },
        name: { required },
        expMonth: {
          required,
          numeric,
          minValue: minValue(this.checkingMonth()),
        },
        expYear: {
          required,
          numeric,
        },
        cvv: {
          required,
          numeric,
          minLength: minLength(0),
          // maxLength: maxLength(3),
        },
      },
      address: {
        address1: { required },
        city: {},
        state: {},
        zip_code: { minLength: minLength(4) },
      },
    };
  },
  setup() {
    const axios = inject("axios");
    const selectedPayment = ref();
    // const selectCard1 = ref();
    const selectCard2 = ref();
    const selectedPaylater = ref(null);
    const isSameBilling = ref(true);
    const discountCoins = ref(null);
    const shippingAddress = ref(null);
    const useCredit = ref(false);
    const showAddressModal = ref(false);
    const showPaymentsModal = ref(false);
    const showSplitPayModal = ref(false);
    const currentStep = ref(1);
    let currentTab = ref(0);
    // const newAddress = {
    //   fname: "",
    //   lname: "",
    //   line1: "",
    //   line2: "",
    //   city: "",
    //   state: "",
    //   zipcode: "",
    // };
    const changeStep = (step) => {
      currentTab.value = step;
    };

    const { address, st, getStates, zipcode, getZipcodes } = Account();
    getStates();
    // Get Addresses
    const { addresses, getAddresses } = Address();
    getAddresses();

    // Get Checkout Data
    const {
      checkoutData,
      getCheckoutData,
      shippingData,
      getShippingMethods,
      coins,
      getCoins,
      couponApply,
      couponRemove,
      pointRemove,
      creditApply,
      applyCoins,
      nmiStepOne,
      cards,
      getCreditCards,
      nmiStepTwo,
      getCheckout,
      getCheckoutItems,
      error,
    } = Checkout();

    const expiredCards = computed(() =>
      (cards.data || []).filter((c) => c.expired == 1)
    );

    async function removeShipping() {
      await axios.authApi.get("/removeDeliveryFee").then((resp) => {
        if (resp.status) {
          // cards.data = resp.data.data;
          getCoins();
          getCheckoutItems();
          getCheckout(1);
          getCreditCards();
        }
      });
    }
    removeShipping();

    const isItemsNoticeVisible = ref(false);
    const isItemsInventoryNoticeVisible = ref(false);
    const isPaymentDeclined = ref(false);
    const hasExpiredCards = ref(false);
    const removeableItems = [];

    const removeItemsFn = () => {
      console.log("Removing Items");
    };

    const expYears = computed(() => {
      const years = [];
      const currentYear = new Date().getFullYear();
      let updatedYear = currentYear;

      while (updatedYear < currentYear + 25) {
        years.push(updatedYear);
        updatedYear++;
      }
      // console.log(years);
      return years;
    });

    watch(
      () => cards.loading,
      () => {
        hasExpiredCards.value = expiredCards.value.length > 0;
      }
    );

    return {
      getAddresses,
      currentStep,
      address,
      st,
      zipcode,
      getZipcodes,
      axios,
      selectedPayment,
      paylaterOptions,
      bankAndZelle,
      isSameBilling,
      discountCoins,
      shippingAddress,
      useCredit,
      showAddressModal,
      showPaymentsModal,
      showSplitPayModal,
      // newAddress,
      currentTab,
      // selectCard1,
      selectCard2,
      selectedPaylater,
      changeStep,
      addresses,
      checkoutData,
      getShippingMethods,
      shippingData,
      coins,
      applyCoins,
      couponApply,
      couponRemove,
      pointRemove,
      creditApply,
      nmiStepOne,
      cards,
      nmiStepTwo,
      getCreditCards,
      expYears,
      // v$: useVuelidate()
      removeableItems,
      isItemsNoticeVisible,
      isItemsInventoryNoticeVisible,
      removeItemsFn,
      hasExpiredCards,
      isPaymentDeclined,
      expiredCards,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkout-page {
  @apply flex flex-col-reverse lg:flex-row items-start lg:space-x-6;

  .card-btn {
    @apply rounded text-xs px-3 py-2 leading-none font-medium text-white bg-theme-secondary inline-flex items-center uppercase tracking-wider;
    svg {
      @apply relative w-4 h-4 -mt-0.5 mr-1;
      bottom: -1px;
    }
    span {
      @apply relative;
      bottom: -1px;
    }
  }

  .mar-left {
    @apply ml-2;
  }

  .address-checkbox {
    @apply flex items-center space-x-2 select-none cursor-pointer;
    input {
      @apply h-4 w-4 text-theme-indigo border-2 border-theme-indigo rounded-sm focus:ring-0 -mt-0.5;
    }
    span {
      @apply font-medium text-base text-theme-body leading-none;
    }
  }

  .order-details {
    @apply flex-grow w-full lg:w-auto space-y-8;
  }

  .order-summary {
    @apply flex flex-col w-full lg:max-w-sm flex-shrink-0 space-y-8 mb-8;
  }
}
.disp-none {
  display: none;
}
.checkblock {
  @apply h-full relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus:outline-none select-none;

  &.has-label {
    @apply mb-8 sm:mb-0;
  }
  &.disabled {
    @apply bg-theme-bg hover:bg-theme-bg border-theme-border hover:border-theme-border cursor-default pointer-events-none;
  }

  &.min-height {
    min-height: 175px;
  }

  .checkmark-label {
    @apply flex w-full justify-end -mb-6;

    &.narrow-margin {
      @apply -mb-2;
    }

    .checkmark {
      @apply border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center;

      &.active {
        @apply bg-theme-indigo border-theme-indigo text-white;
      }

      &.inactive {
        @apply bg-white border-theme-border;
      }
    }
  }
  .go-back {
    @apply mb-4 bg-gray-100 p-3 justify-between;
    width: fit-content;
    border-radius: 100%;
  }

  &.back-block {
    .item-desc {
      @apply m-0 w-full;
    }
  }

  .item-desc {
    @apply block text-sm text-theme-secondary font-normal text-opacity-75 w-11/12 mb-3;

    &.no-margin {
      @apply mb-0;
    }
  }

  .item-nickname {
    @apply text-xs font-normal text-theme-body mb-1;
  }
}

.billing-info {
  @apply flex flex-col justify-between flex-grow w-full lg:w-auto;

  dl {
    @apply grid grid-cols-1 md:grid-cols-2 gap-6 text-sm px-8 pt-6 mb-8 text-theme-body-light;

    dt {
      @apply block uppercase text-xs tracking-wider font-light;
    }
  }

  .address-title {
    @apply block text-md text-theme-secondary font-medium mb-0.5;
  }

  .address-info {
    @apply block text-sm text-theme-body font-medium;
    &.highlight {
      @apply text-brand-gray;
    }
  }
}

.info-title {
  @apply flex items-center justify-between px-4 py-3;
  .title {
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg {
      @apply w-5 h-5;
    }
  }
  .price {
    @apply text-sm text-black font-semibold;
  }
}
.payNow-loader {
  background-color: rgba(0, 0, 0, 0.1);
  /* opacity: 0; */
  transition: opacity 0.2s ease;
}
.wd-50 {
  max-width: 50rem;
  width: 90vw;
}
.modal {
  ::v-deep .input {
    input {
      @apply h-auto md:h-14;
    }
  }
  input {
    margin-top: 0.1rem;
    border: 2px solid #e9e9e9;
    border-radius: 0.25rem;
    @apply h-auto md:h-14;
  }
  .ss-btn {
    border: 1px solid #e9e9e9;
    border-radius: 0.25rem;
    margin-top: 0.4rem;
  }
}
.store-switcher {
  @apply relative w-full inline-block text-left;

  > div > label {
    @apply block w-full text-left;
  }

  .ss-btn {
    @apply w-full px-6 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent mt-0;
  }

  .item-opts {
    @apply z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none;

    .item-opt {
      @apply w-full px-4 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent cursor-pointer;
    }

    .name {
      @apply text-brand-secondary text-sm font-medium truncate;
    }

    .place {
      @apply text-brand-body text-xs truncate;
    }
  }
}
.CardDisable {
  pointer-events: none;
  background: #d4d4d4 !important;
  padding-top: 2.3em !important;
}
.number-input {
  @apply flex items-center bg-white rounded px-2 py-0.5 border-2 border-brand-ash border-opacity-50 hover:border-brand-ash z-10 relative;
  min-width: 100px;

  &.transparent-bg {
    @apply bg-transparent;
  }

  &.transparent-border {
    @apply border-transparent;
  }

  > span {
    @apply text-md text-theme-dark;
  }

  &.medium {
    @apply px-2 py-1.5 text-base;
  }

  input {
    @apply flex-grow bg-transparent text-sm appearance-none block leading-none rounded text-brand-secondary focus:outline-none border-none focus:ring-0 text-left py-0 px-1;

    @include inputPlaceholder() {
      @apply text-sm text-brand-secondary leading-snug;
    }
    @include resetAutoComplete() {
      @apply text-sm text-brand-secondary leading-snug;
    }

    &:disabled {
      @apply bg-brand-smoke bg-opacity-5 cursor-default;
      &:hover {
        @apply border-brand-smoke;
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .btns {
    @apply flex flex-col items-center justify-between px-0.5;
  }
  button {
    @apply inline-block font-light text-lg text-brand-body w-3 focus:outline-none cursor-pointer hover:text-brand-secondary uppercase leading-none;

    &:disabled {
      @apply opacity-30 cursor-default hover:opacity-30;
    }
  }
}

.cards-list {
  @apply overflow-y-auto relative -mr-2 pr-2;
  @include CssScrollbar();
  @media screen and (max-width: 767px) {
    max-height: 40vh;
  }

  @media screen and (min-width: 768px) {
    max-height: max(340px, 40vh);
  }
}
</style>
